<template>
  <div class="gpt-drawing" v-loading="dataLoading">
    <el-card class="gpt-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>Prompt</span>
        <el-button class="top-botton" type="primary" size="small" icon="el-icon-check"
          @click="setSettings">Save</el-button>
        <el-button class="top-botton top-next-botton" type="" size="small" icon="el-icon-refresh"
          @click="fetchSettings">Fresh</el-button>
      </div>
      <el-form class="gpt-form" ref="form" label-width="120px" :model="form" size="mini">
        <el-form-item label="Basic">
          <el-input type="textarea" v-model="form.basic.value" autosize></el-input>
        </el-form-item>
        <el-form-item label="Negative">
          <el-input type="textarea" v-model="form.negative.value" autosize></el-input>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Color">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('drawingColor')">
          </el-button>
          <el-form-item v-for="item in form.color" :key="item.key">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'drawingColor', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key }}</template>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Pattern">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('drawingPattern')">
          </el-button>
          <el-form-item v-for="item in form.pattern" :key="item.key">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'drawingPattern', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key }}</template>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Fabric">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('drawingFabric')">
          </el-button>
          <el-form-item v-for="item in form.fabric" :key="item.key">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'drawingFabric', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key }}</template>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { fetchGptSettings, setGptSettings, addGptSettingsType, deleteGptSettingsType } from "@/api/admin";

export default {
  data() {
    return {
      form: {
        basic: { type: "drawingBasic", value: "" },
        negative: { type: "drawingNegative", value: "" },
        color: [],
        pattern: [],
        fabric: [],
      },
      dataLoading: false,
    };
  },
  methods: {
    clearForm() {
      this.form = {
        basic: { type: "drawingBasic", value: "" },
        negative: { type: "drawingNegative", value: "" },
        color: [],
        pattern: [],
        fabric: [],
      };
    },
    fetchSettings() {
      this.dataLoading = true;
      fetchGptSettings({ category: "drawing" })
        .then((response) => {
          if (response.success) {
            this.clearForm();
            response.result.forEach((t) => {
              if (t.type === "drawingBasic") {
                this.form.basic = t;
              } else if (t.type === "drawingNegative") {
                this.form.negative = t;
              } else if (t.type === "drawingColor") {
                this.form.color.push(t);
              } else if (t.type === "drawingPattern") {
                this.form.pattern.push(t);
              } else if (t.type === "drawingFabric") {
                this.form.fabric.push(t);
              }
            });
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    setSettings() {
      let v = [];
      v.push(this.form.basic);
      v.push(this.form.negative);
      v = v.concat(this.form.color);
      v = v.concat(this.form.pattern);
      v = v.concat(this.form.fabric);
      this.dataLoading = true;
      setGptSettings({ data: v })
        .then((response) => {
          if (response.success) {
            this.$notify({
              title: "Update successful",
              type: "success",
            });
            this.fetchSettings();
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    active() {
      this.fetchSettings();
    },
    addKey(type) {
      this.$prompt('It takes effect immediately after confirm', 'Create Key', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
        inputValidator: (value) => {
          if (!value) {
            return "key can not be empty"
          }
        }
      }).then(({ value }) => {
        addGptSettingsType({ "type": type, "key": value }).then(response => {
          if (response.success) {
            this.$notify({
              title: "Create successful",
              type: "success",
            });
            this.fetchSettings();
          }
        })
      }).catch(() => {
      });
    },
    removeOption(data) {
      this.$confirm('It takes effect immediately after confirm', 'Delete this key?', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        deleteGptSettingsType(data).then(response => {
          if (response.success) {
            this.$notify({
              title: "Delete successful",
              type: "success",
            });
            this.fetchSettings();
          }
        })
      }).catch(() => {
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.gpt-drawing {
  .gpt-card {
    margin-bottom: 10px;

    .top-botton {
      float: right;
      margin-top: -8px;
      margin-bottom: -8px
    }

    .top-next-botton {
      margin-right: 10px;
    }


    .gpt-form {
      margin-bottom: -10px;

      .add-botton {
        margin-bottom: 18px;
        display: block
      }

      .row-input {
        margin-top: 1px
      }

      .no-bottom-margin {
        margin-bottom: 0;
      }
    }
  }
}
</style>