<template>
  <div class="admin-user-data">
    <el-row :gutter="20">
      <el-col :span="11">
        <el-card class="user-card" shadow="never">
          <div slot="header">
            <span>1. User</span>
          </div>
          <user-selection :id="current.userId" @select="selectUserId" :lite="true" />
        </el-card>
      </el-col>
      <el-col :span="13">
        <el-row>
          <el-card class="data-card" shadow="never">
            <div class="data-brand-card-header" slot="header">
              <span>2. Morph Face</span>
              <el-button :disabled="current.userId === 0" type="primary" size="small"
                @click="openAddDialog('morph-face')" icon="el-icon-plus">Add</el-button>
            </div>
            <span v-if="current.userId === 0">Please select user first!</span>
            <el-form v-else size="small">
              <el-row :gutter="5">
                <el-col class="target-block" :span="6" v-for="item in dataList['morph-face']" :key="item.id">
                  <el-row>
                    <span class="target-name">{{ item.name }}</span>
                    <el-popconfirm title="Delete Target?" @confirm="deleteTarget(item.id, 'morph-face')">
                      <el-button class="danger-color target-button" icon="el-icon-delete" type="text"
                        slot="reference"></el-button>
                    </el-popconfirm>
                  </el-row>
                  <el-row class="target-img-wraper">
                    <el-image class="target-img" :src="item.url | imageUrl(false)"
                      :preview-src-list="[imageUrl(item.url, false)]" fit="contain" />
                  </el-row>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-row>
        <el-row>
          <el-card class="data-card" shadow="never">
            <div class="data-brand-card-header" slot="header">
              <span>3. Concept Face</span>
              <el-button :disabled="current.userId === 0" type="primary" size="small"
                @click="openAddDialog('concept-face')" icon="el-icon-plus">Add</el-button>
            </div>
            <span v-if="current.userId === 0">Please select user first!</span>
            <el-form v-else size="small">
              <el-row :gutter="5">
                <el-col class="target-block" :span="6" v-for="item in dataList['concept-face']" :key="item.id">
                  <el-row>
                    <span class="target-name">{{ item.name }}</span>
                    <el-popconfirm title="Delete Target?" @confirm="deleteTarget(item.id, 'concept-face')">
                      <el-button class="danger-color target-button" icon="el-icon-delete" type="text"
                        slot="reference"></el-button>
                    </el-popconfirm>
                    <el-button class="main-color target-button" icon="el-icon-edit-outline" type="text"
                      @click="openTagDialog(item)"></el-button>
                  </el-row>
                  <el-row class="target-img-wraper">
                    <el-image class="target-img" :src="item.url | imageUrl(false)"
                      :preview-src-list="[imageUrl(item.url, false)]" fit="contain" />
                  </el-row>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-row>
        <el-row>
          <el-card class="data-card" shadow="never">
            <div class="data-brand-card-header" slot="header">
              <span>4. Concept Pose</span>
              <el-button :disabled="current.userId === 0" type="primary" size="small"
                @click="openAddDialog('concept-pose')" icon="el-icon-plus">Add</el-button>
            </div>
            <span v-if="current.userId === 0">Please select user first!</span>
            <el-form v-else size="small">
              <el-row :gutter="5">
                <el-col class="target-block" :span="6" v-for="item in dataList['concept-pose']" :key="item.id">
                  <el-row>
                    <span class="target-name">{{ item.name }}</span>
                    <el-popconfirm title="Delete Target?" @confirm="deleteTarget(item.id, 'concept-pose')">
                      <el-button class="danger-color target-button" icon="el-icon-delete" type="text"
                        slot="reference"></el-button>
                    </el-popconfirm>
                  </el-row>
                  <el-row class="target-img-wraper">
                    <el-image class="target-img" :src="item.url | imageUrl(false)"
                      :preview-src-list="[imageUrl(item.url, false)]" fit="contain" />
                  </el-row>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-row>
        <el-row>
          <el-card class="data-card" shadow="never">
            <div class="data-brand-card-header" slot="header">
              <span>5. Concept Lora</span>
              <el-button :disabled="current.userId === 0" type="primary" size="small" @click="saveTargetLora"
                icon="el-icon-check">Save</el-button>
            </div>
            <span v-if="current.userId === 0">Please select user first!</span>
            <div v-else>
              <el-transfer ref="loraTransfer" filterable v-model="selectedLoraList" :data="loraList"
                :titles="['Available', 'Select']">
              </el-transfer>
            </div>
          </el-card>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="dialogVisible" @close="closeAddDialog" title="Add Target Face" custom-class="add-dialog">
      <el-form ref="form" :model="current" :rules="rules" size="small" label-width="120px">
        <el-form-item label="Name" prop="name">
          <el-input v-model="current.name"></el-input>
        </el-form-item>
        <el-form-item label="Image">
          <el-upload :action="aiApiRoot + '/v1/admins/gpt-target'" accept=".jpg, .jpeg, .png" ref="upload"
            :headers="{ Authorization: this.token }"
            :data="{ userId: current.userId, name: current.name, group: currentGroup }" list-type="picture-card"
            :on-success="() => { handleUploadSuccess(currentGroup) }" :fileList="fileList" :auto-upload="false"
            :multiple="false" :limit="1">
            <i v-if="fileList.length === 0" class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeAddDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmUpload">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import UserSelection from "./UserSelection.vue";
import { fetchTargets, deleteTarget, fetchTargetLoraAvailables, saveTargetLora, setTargetTag } from "@/api/admin";
import { imageUrl } from "@/filters";
export default {
  components: { UserSelection },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      dialogVisible: false,
      currentGroup: "",
      dataList: {
        "morph-face": [],
        "concept-face": [],
        "concept-pose": []
      },
      loraList: [],
      selectedLoraList: [],
      fileList: [],
      current: {
        userId: 0,
        name: ""
      },
      rules: {
        name: [{ required: true }],
      }
    };
  },
  methods: {
    active() {
      this.fetchTargetLoraAvailables();
    },
    fetchTargetLoraAvailables() {
      fetchTargetLoraAvailables().then(response => {
        if (response.success) {
          let loraList = response.result ? response.result : [];
          this.loraList = loraList.map(e => {
            return {
              key: e,
              label: e,
              disabled: false,
            }
          });
        }
      })
    },
    openAddDialog(group) {
      this.dialogVisible = true;
      this.currentGroup = group;
    },
    closeAddDialog() {
      this.dialogVisible = false;
      this.fileList = [];
      this.$refs.form.resetFields();
    },
    openTagDialog(item) {
      this.$prompt('should be "W", "M", or "CW", "CM"', 'Please input sex', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputValue: item.tags ? item.tags.sex : ''
      }).then(({ value }) => {
        setTargetTag(item.id, { tags: { sex: value } }).then(response => {
          if (response.success) {
            this.$notify({
              title: "Update successful",
              type: "success",
            });
            this.fetchTarget("concept-face");
          }
        })
      });
    },
    selectUserId(userId) {
      this.current.userId = userId;
      if (userId) {
        this.fetchTarget("morph-face");
        this.fetchTarget("concept-face");
        this.fetchTarget("concept-pose");
        this.fetchTargetLora();
        let lt = this.$refs.loraTransfer;
        if (lt) {
          lt.clearQuery();
        }
      }
    },
    fetchTarget(group) {
      this.dataList[group] = [];
      fetchTargets({ limit: -1, userId: this.current.userId, group: group }).then(response => {
        if (response.success) {
          this.dataList[group] = response.result && response.result.items ? response.result.items : [];
        }
      })
    },
    deleteTarget(id, group) {
      deleteTarget(id).then(response => {
        if (response.success) {
          this.$notify({
            title: "Delete successful",
            type: "success",
          });
          this.fetchTarget(group);
        }
      });
    },
    handleUploadSuccess(group) {
      this.$notify({
        title: "Add successful",
        type: "success",
      });
      this.closeAddDialog();
      this.fetchTarget(group);
    },
    confirmUpload() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$refs.upload.submit();
      })
    },
    imageUrl(url, isExternal) {
      return imageUrl(url, isExternal);
    },
    fetchTargetLora() {
      this.selectedLoraList = [];
      fetchTargets({ limit: -1, userId: this.current.userId, group: "concept-lora" }).then(response => {
        if (response.success) {
          let items = response.result && response.result.items ? response.result.items : [];
          this.selectedLoraList = items.map(t => {
            return t.name;
          })
        }
      })
    },
    saveTargetLora() {
      saveTargetLora({
        userId: this.current.userId,
        group: "concept-lora",
        names: this.selectedLoraList
      }).then(response => {
        if (response.success) {
          this.$notify({
            title: "Update successful",
            type: "success",
          });
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.admin-user-data {
  .danger-color {
    color: #F56C6C;
  }

  .user-card {
    margin-bottom: 10px;
  }

  .data-card {
    margin-bottom: 10px;

    .data-brand-card-header .el-button {
      margin-left: 10px;
      float: right;
      margin-top: -7px;
    }

    .target-block {
      .target-name {
        display: inline-block;
        line-height: 1;
        padding: 12px 0;
        color: #606266;
      }

      .target-button {
        float: right;
        margin-left: 10px;
      }

      .target-img-wraper {
        width: 100%;
        height: 0;
        padding: 0;
        padding-bottom: 100%;

        .target-img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }

    .lora-item {
      display: block;
    }
  }

  /deep/ .add-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
  }

  /deep/ .el-transfer-panel {
    width: 270px;
  }

  /deep/ .el-transfer__button {
    display: block;
    margin: 0 auto;
    padding: 10px;
    border-radius: 50%;
    font-size: 0;
  }

  /deep/ .el-transfer__buttons {
    padding: 0 17px;
  }

  /deep/ .el-transfer__button:first-child {
    margin-bottom: 10px;
  }
}
</style>